<template>
  <v-container class="pa-0">
    <v-dialog :width="this.size != null ? size : '400'" v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-list-item class="braList" v-on="on">
          <v-list-item-icon>
            <v-icon small>$editprofile</v-icon></v-list-item-icon
          >
          <v-list-item-title>
            <span>{{ $t("edit", { name: "" }) }}</span></v-list-item-title
          >
        </v-list-item>
      </template>

      <v-card>
        <v-card-title>
          {{ $t("routes.brandEdit") }}
        </v-card-title>
        <v-card-text>
          <brands-form
            :title="$t('new', { name: $tc('brand') })"
            :brand="brand"
            @validated="updateBrand"
            @back="dialog = false"
          ></brands-form
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "BrandEdit",
  components: { BrandsForm: () => import("@/components/brand/Form") },
  props: ["id"],
  mounted() {
    this.fetchBrand();
  },
  data() {
    return {
      brandSaved: false,
      validationErrors: [],
      brand: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("brand", ["editBrand", "getBrand"]),
    fetchBrand() {
      this.getBrand(this.id).then((response) => {
        this.brand = response;
      });
    },
    updateBrand() {
      //console.log("update");
      this.editBrand({
        brand_id: this.id,
        brand: this.brand,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("brand_edit_success"), "", "");
          this.$emit("updated");
          this.dialog = false;
        }
      });
    },
  },
};
</script>